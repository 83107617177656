@tailwind base;
@tailwind components;
@tailwind utilities;

.ReactCollapse--collapse {
  transition: height 500ms;
}

* {
  scroll-behavior: smooth;
  box-sizing: border-box;
}
.bgr {
  background-image: url(../src/img/IMG_6101.JPG);
  object-fit: contain;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.my-div {
  background: rgba(215, 215, 215, 0.5);
  backdrop-filter: blur(10px);
}